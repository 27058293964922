<template>
  <div class="case">
    <img v-for="(item,index) in pageAd" :key="index" :src="serverUrl + item.savePath" alt="">
    <div class="tabList">
      <div class="tabs-wrap">
        <div class="tabs">
          <div class="item" :class="tabIndex === index ? 'active' : ''" v-for="(item,index) in tabs"
               :key="index" @click="selectTabItem(index)">{{ item }}
          </div>
        </div>
      </div>

      <div class="tabs-content">
        <div class="first-tab" v-if="tabIndex === 0">
          <div class="first-tab">
            <p class="title">工程案例</p>
          </div>
          <div class="case-content">
            <el-row>
              <el-col v-for="(item, index) in dataList" :key="index" :xs="24" :sm="6">
                <div class="case-about" @click="toMore(item)">
                  <img class="case-img" :src="serverUrl + item.coverImage" alt="">
                  <div class="case-index">{{ item.title }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <div class="pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryCondition.pageNum"
            :page-size="queryCondition.pageSize"
            :page-sizes="[10,20]"
            layout="total, prev, pager, next"
            :total="queryCondition.total">
        </el-pagination>
      </div>
    </div>
    <div class="tab-bottom"></div>
  </div>
</template>

<script>
import {getAd, getArts} from "../../api/api"
import {serverUrl} from "@/utils/config";

export default {
  name: "Index",
  data() {
    return {
      serverUrl: serverUrl,
      activeName: "first",
      dataList: [],
      currentPage: 1, //初始页
      pageSize: 8,    //    每页的数据
      paginationList: [],
      tabs: ["工程案例"],
      tabIndex: 0,
      queryCondition: {
        categoryId: 9,
        pagination: true,
        containsContent: false,
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      pageAd: []
    };
  },
  created() {
    this.getPageData()
    this.getPageAd()
  },
  methods: {
    getPageAd(){
      getAd({
        code: 'projs_ad'
      }).then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.pageAd = data;
        }
      })
    },
    getPageData() {
      getArts(this.queryCondition).then((resp) => {
        let {code, data} = resp;
        let { rows,total } = data;
        if (code === 0) {
          this.dataList = rows;
          this.queryCondition.total = total;
        }
      })
    },
    selectTabItem(tabIndex) {
      this.tabIndex = tabIndex;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange: function (size) {
      this.queryCondition.pageSize = size;
      this.getPageData()
    },
    handleCurrentChange: function (currentPage) {
      this.queryCondition.pageNum = currentPage;
      this.getPageData()
    },
    toMore(data) {
      this.$router.push({
        path: '/new_detail', query: {
          id: data.id,
          backPage: 'case',
          backTitle: '工程案例',
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
